import React, {memo, useContext, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import Counter from '../../components/layout/header/components/counter';
import ProductCard from '../../components/pricing/productCard';
import Layout from '../../components/layout';
import Section from '../../components/section';
import {UserContext} from '../../contexts/user-context';
import {useMediaQuery} from 'react-responsive';
import {closeIcon} from '../../images/svgIcons';
import pricingIcon from '../../images/payments/pricing-hint.png';
import './styles.scss';

const Pricing = ({pageContext}: any) => {
  const [plans, setPlans] = useState({
    annual: null as any,
    monthly: null as any,
    ltd: null as any,
  });
  const [disableAll, setDisableAll] = useState(false);
  const {user, hexosparkUser} = useContext(UserContext);
  const [annual, setAnnual] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('');
  const [currentPlanInterval, setCurrentPlanInterval] = useState('');
  const [paypalSubscriptionId, setPaypalSubscriptionId] = useState('');
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [pendingPayment, setPendingPayment] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const specifiedDate = new Date('Jun 14 2024 07:00:00 GMT');
    const promoClosed = currentDate.getTime() > specifiedDate.getTime();
    if (
      (hexosparkUser?.hsUser &&
      hexosparkUser?.hsUser?.pricing_package_interval !== 'LTD' &&
      hexosparkUser?.hsUser?.pricing_package !== 'FREE') || promoClosed
    ) {
      navigate('/pricing/');
    }
  }, [hexosparkUser]);

  useEffect(() => {
    const annualStripePlans = pageContext?.products?.filter(
      (item: any) => item.productType === 'STRIPE' && item.name !== 'FREE' && item.interval === 'ANNUAL',
    );
    const monthlyStripePlans = pageContext?.products?.filter(
      (item: any) => item.productType === 'STRIPE' && item.name !== 'FREE' && item.interval === 'MONTHLY',
    );

    const annualPlansWithPaypalId = annualStripePlans?.map((item: any) => {
      const annualPaypal = pageContext?.products?.find(
        (sub: any) =>
          sub.productType === 'PAYPAL' && sub.name !== 'FREE' && sub.interval === 'ANNUAL' && sub.name === item.name,
      );
      return {...item, paypal_id: annualPaypal?.id};
    });

    const monthlyPlansWithPaypalId = monthlyStripePlans?.map((item: any) => {
      const monthPaypal = pageContext?.products?.find(
        (sub: any) =>
          sub.productType === 'PAYPAL' && sub.name !== 'FREE' && sub.interval === 'MONTHLY' && sub.name === item.name,
      );
      return {...item, paypal_id: monthPaypal?.id};
    });

    const ltdStripe = pageContext?.products?.filter(
        (item: any) =>
          item.productType === 'STRIPE' && item.name !== 'FREE' && item.name === 'PAID' && item.interval === 'LTD',
      )
      .sort((obj1: any, obj2: any) => {
        if (obj1.amount > obj2.amount) {
          return 1;
        } else {
          return -1;
        }
      });
    const ltdWithPaypalID = ltdStripe?.map((item: any) => {
      const ltdPaypal = pageContext?.products?.find(
        (sub: any) =>
          sub.productType === 'PAYPAL' && sub.name !== 'FREE' && sub.interval === 'LTD' && sub.name === item.name,
      );
      return {...item, paypal_id: ltdPaypal?.id};
    });

    const finArrAnnual = annualPlansWithPaypalId?.map((item: any) => {
      if (
        (paypalSubscriptionId && currentPlan === 'TEAM') ||
        (currentPlanInterval === 'LTD' && item.interval !== 'LTD') ||
        (currentPlan !== 'FREE' && item.name === 'SOLO')
      ) {
        return {...item, disabled: true};
      } else {
        return item;
      }
    });
    const finArrMonth = monthlyPlansWithPaypalId?.map((item: any) => {
      if (
        (paypalSubscriptionId && currentPlan === 'TEAM') ||
        (currentPlanInterval === 'LTD' && item.interval !== 'LTD') ||
        currentPlanInterval === 'ANNUAL' ||
        (currentPlan !== 'FREE' && item.name === 'SOLO')
      ) {
        return {...item, disabled: true};
      } else {
        return item;
      }
    });
    const finArrLtd = ltdWithPaypalID?.map((item: any) => {
      if (
        currentPlan === 'PAID' ||
        ((currentPlan === 'SOLO' || currentPlan === 'TEAM') && currentPlanInterval === 'LTD' && item.name === 'SOLO') ||
        (currentPlanInterval !== 'LTD' && currentPlan !== 'FREE')
      ) {
        return {
          ...item,
          disabled: true,
          name: 'SOLO',
        };
      } else {
        return {...item, disabled: false, name: 'SOLO'};
      }
    });
    finArrLtd.push({
      ...finArrLtd[0],
      name: 'TEAM',
      disabled: false,
    });
    const ltdNothDisabledArr = finArrLtd?.filter((item: any) => !item.disabled);
    // !monthNothDisabledArr?.length && setAnnual(true);
    setDisableAll(!finArrLtd?.length);
    setContactModalOpen(!ltdNothDisabledArr?.length);
    setPlans({
      annual: finArrAnnual,
      monthly: finArrMonth,
      ltd: finArrLtd,
    });
  }, [pageContext, currentPlan, currentPlanInterval, paypalSubscriptionId, user]);

  useEffect(() => {
    if (hexosparkUser?.hsUser && typeof hexosparkUser.hsUser.pending_payment === 'boolean') {
      setPendingPayment(hexosparkUser.hsUser.pending_payment);
    } else {
      setPendingPayment(false);
    }
    setCurrentPlan(hexosparkUser?.hsUser?.pricing_package || 'FREE');
    setCurrentPlanInterval(hexosparkUser?.hsUser?.pricing_package_interval || '');
    setPaypalSubscriptionId(hexosparkUser?.hsUser?.paypal_subscription_id || '');
  }, [hexosparkUser]);

  const isMobile = useMediaQuery({maxWidth: 1250});
  //------------------Query---------------------
  return (
    <Layout promoPage ltd>
      <Section pricing light pagehero pageheroPading lgPd>
        <h1 className="f_size_48 f_600 t_color4 l_height54 text-center">Hexospark Pricing Plans</h1>
        <div className="text-center f_size_24" style={{color: '#516077'}}>
          Flash promo! Get lifetime access from just <span className="line-through-span">$29/monthly</span> $69 one-time
          payment.
        </div>
        <div className="mt-4">
          <Counter date={'Jun 14 2024 07:00:00 GMT'} title="DEAL ENDS IN..." titleColor="#00042a" />
        </div>
      </Section>
      <div className="pricing-wrapper" id="early-access">
        {contactModalOpen && (
          <div className="text-center all-disabled mt-4">
            <span onClick={() => setContactModalOpen(false)}>{closeIcon}</span>
            <img src={pricingIcon} alt="icon" />
            <div>Need to upgrade your plan? </div>
            <div className="support">
              Please contact{' '}
              <a
                href="mailto:support@hexospark.com"
                className="clickable-text"
                aria-label={'mail'}
                target="_blank"
                rel="noopener"
              >
                support@hexospark.com
              </a>
              {'.'}
            </div>
          </div>
        )}
        <div className="d-flex products-wrapper justify-content-center" id='deal'>
          {!pageContext ? (
            <div className="w-100 d-flex">
              <div className="spinner-border spinner-color m-auto" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : null}
          {plans?.ltd?.map((item: any, index: number) => (
            <ProductCard
              id={item.id}
              paypal_id={item.paypal_id}
              color={item.color}
              stack={false}
              //count={parseInt(item.name.replace('PAID_', ''))}
              key={item.name}
              price={item.amount}
              expirationDate={true}
              pendingPayment={pendingPayment}
              user={!!(user && user.id)}
              verified={!!user?.verified}
              annual={annual}
              ltdUser={currentPlanInterval === 'LTD'}
              ltd={true}
              monthlyPrice={plans?.monthly[index]?.amount}
              annualPrice={plans?.annual[index]?.amount}
              disabled={item.disabled || disableAll}
              name={item.name === 'SOLO' ? 'Solo' : 'Team'}
            />
          ))}
          {plans.monthly && (
            <ProductCard stack={false} expirationDate={true} pendingPayment={pendingPayment} name="Enterprise" />
          )}
        </div>

        <p className={isMobile ? 'text-center mt-1' : 'text-center mt-5'}>
          To use Hexospark you will need an existing email account. SMTP, native Gmail and Outlook 365 integrations are
          supported.
        </p>
      </div>
    </Layout>
  );
};

export default memo(Pricing);
